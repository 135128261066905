<template>
  <div style="width: 1000px; height: 1000px">
    <!-- <editor v-model="yourContent"></editor> -->
    <div>
      <Toolbar :editor="editor" :mode="mode" :defaultConfig="toolbarConfig" />
    </div>
    <div>
      <Editor
        style="height: 300px"
        v-model="html"
        :defaultConfig="editorConfig"
        :mode="mode"
        @onChange="onChange"
        @onCreated="onCreated"
      />
    </div>
  </div>
</template>
<script>
import { Editor, Toolbar } from "@wangeditor/editor-for-vue";
import { getSAuth, getSchool } from "@/utils/auth.js";
import env from "@/settings/env.js";
// import Editor from "@/components/Editor.vue";
export default {
  components: {
    // editor: Editor,
    Editor,
    Toolbar,
  },
  data() {
    return {
      editor: null,
      html: "",
      toolbarConfig: {
        modalAppendToBody: true,
        excludeKeys: ["emotion"],
      }, //富文本工具栏配置
      editorConfig: {
        // 富文本菜单栏配置
        placeholder: "请输入内容...",
        MENU_CONF: {
          uploadImage: {
            server: env.apiUrlPrefix + "api/upload/files",
            fieldName: "imagelist",
            headers: {
              authorization: getSAuth(),
              school: getSchool(),
            },
            customInsert(res, insertFn) {
              // res 即服务端的返回结果

              // 从 res 中找到 url alt href ，然后插图图片
              insertFn(res.data.url);
            },
          },
          uploadVideo: {
            server: env.apiUrlPrefix + "api/upload/video",
            fieldName: "imagelist",
            headers: {
              authorization: getSAuth(),
              school: getSchool(),
            },
            customInsert(res, insertFn) {
              // res 即服务端的返回结果

              // 从 res 中找到 url alt href ，然后插图图片
              insertFn(res.data.url);
            },
          },
        },
      },
      mode: "simple ", // or 'simple'
    };
  },
  beforeDestroy() {
    const editor = this.editor;
    if (editor == null) return;
    editor.destroy(); // 组件销毁时，及时销毁编辑器
  },
  methods: {
    onCreated(editor) {
      this.editor = Object.seal(editor); // 一定要用 Object.seal() ，否则会报错
    },
    onChange(val) {},
  },
};
</script>
<style src="@wangeditor/editor/dist/css/style.css"></style>
<style lang="" scoped>
</style>